.container {
  background-color: #1d252c;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background: #333333;
  border-radius: 8px;
  // display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  font-size: 20px;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22);

  a {
    color: #1d88e5;
  }
}

.fileInput {
  display: none;
}
.selectBtn {
  display: inline-block;
  margin-top: 20px;
  -webkit-appearance: none;
  outline: none;
  font-size: 18px;
  padding: 12px 24px;
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: #808080 0px 1px 3px;
  border: none;

  border-radius: 6px;
  background-color: #1d88e5;
  color: white;
}
