.hoverTime {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: rgba(#000, 0.9);
  line-height: 18px;
  font-size: 16px;
  color: #ddd;
  top: -5px;
  left: 0;
  padding: 2px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(#000, 0.3);
  transform: translate(-50%, -100%);
  pointer-events: none;
  z-index: 10;
}

.hoverVideo {
  height: 100px;
  width: 160px;
  object-fit: cover;
  padding-bottom: 5px;
}
