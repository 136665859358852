.container {
  box-sizing: border-box;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 101%;
  width: 420px;
  background-color: #333333;
  transition: transform 200ms ease-in-out;
  transform: translateX(100%);
  text-align: left;
  color: white;
  padding: 20px;
  overflow-y: scroll;

  &.container__active {
    transform: translateX(0);
  }
}

.exitButton {
  z-index: 100;
  position: fixed;
  top: 45px;
  right: 20px;
  cursor: pointer;
}

.headerText {
  padding-bottom: 12px;
  border-bottom: 1px solid hsla(0, 0%, 100%, 0.15);
}

.mainHeader {
  padding-bottom: 10px;

  p {
    color: #adadad;
  }
}

.subHeaderText {
  color: white;
}

.list {
  list-style: none;
  margin: 0 0 10px;
  color: #adadad;
}

.listOption {
  display: flex;
  padding: 8px 0;
  font-size: 18px;
}

.optionLabel {
  display: block;
  padding-right: 10px;
  align-self: flex-start;
  flex-grow: 1;
}

.optionInput {
  display: flex;
  align-items: center;
  justify-content: center;
}

.displayValue {
  display: block;
  font-size: 14px;
  line-height: 21px;
  font-weight: 700;
  text-align: left;
  padding-left: 10px;
  flex-basis: 20px;
  width: 30px;
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 12px;
  background-color: #fff;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: hsla(0, 0%, 100%, 0.75);
  }

  &.checkbox__checked {
    background-color: #1e88e5;
  }

  input {
    visibility: hidden;
  }
}
