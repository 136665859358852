.VideoControls {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 10;
  color: white;
  opacity: 0;
  transition: opacity 200ms 1s;

  &:hover {
    z-index: 30;
    opacity: 1;
    transition: opacity 200ms;
  }
}

.VideoControls__BackBtn {
  position: fixed;
  top: 30px;
  left: 15px;
  cursor: pointer;
}

.VideoControls__openSubPanel {
  position: fixed;
  top: 30px;
  right: 15px;
  cursor: pointer;
  z-index: 120;
}

.VideoControls__openSubtitleViewerBtn {
  position: fixed;
  top: 30px;
  right: 45px;
  cursor: pointer;
  z-index: 120;
}

.VideoControls__buttons {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  height: 36px;
}

.VideoControls__buttonsRight {
  display: flex;
}

.VideoControls__volumeControl {
  width: 100px;
  display: flex;

  .rangeslider {
    margin-top: 9px;
    flex-grow: 1;
    height: 2px;
  }

  .rangeslider__handle {
    width: 12px !important;
    height: 12px !important;
  }
}

.VideoControls__volumeControlIcon {
  margin-right: 10px;
}

.VideoControls__fullScreen {
  margin-left: 10px;
}

.VideoControls__seekerBottom {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  transition: transform 200ms 1s;
  transform: translateY(0px);

  .VideoControls:hover & {
    transform: translateY(-65px);
    transition: transform 200ms;
  }
}
.VideoControls__seekerBg {
  background-color: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

.VideoControls__lbHitbox {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 120px;
  height: 60px;
}

.VideoControls__rbHitbox {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 120px;
  height: 60px;
}
