.VideoSubController {
  position: absolute;
  top: -20px;
  left: 50vw;
  z-index: 20;
  border-radius: 3px;
  user-focus: none;
  display: flex;
  color: white;
  background-color: #2583df;
  transform: translateX(-50%);

  opacity: 0;
  transition: opacity 200ms 2s;

  &--active,
  &:hover {
    opacity: 1;
    transition: opacity 200ms 0s;
  }
}

.VideoSubController__button {
  &:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  &:active {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &--replay {
    width: 20px !important;
  }
}
