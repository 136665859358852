.VideoSubs {
  z-index: 20;
  margin-top: 20px;
  background-color: #222;
  border-radius: 3px;
  user-focus: none;

  &:hover {
    background-color: #333;
  }

  &--hidden {
    visibility: hidden;
  }
}

.VideoSubs_button {
  position: absolute;
  right: -100px;
  top: 30px;
  color: white;
  cursor: pointer;
  background-color: #222;
  border-radius: 3px;
  padding: 4px 10px;

  &:hover {
    background-color: #333;
  }
}

.VideoSubs_translated {
  color: white;
  font-size: 19px;
  line-height: 22px;
  text-align: center;
  padding: 2px 8px;
  margin-top: 4px;
}

.VideoSubs__subLine {
  position: relative;
  padding: 4px 10px;
}

.VideoSubs__subLineBtn {
  position: relative;
  z-index: 10;
  margin-top: -8px;
  padding: {
    top: 3px;
    bottom: 4px;
    left: 4px;
    right: 4px;
  }
  color: white;
  cursor: pointer;
  font-size: 11px;
  opacity: 0.2;

  &:hover {
    opacity: 1;
    background-color: #2583df;
  }

  @media (max-height: 600px) {
    display: none;
  }
}
