.container {
  width: 100px;
  display: flex;

  :global {
    .rangeslider {
      margin-top: 9px;
      flex-grow: 1;
      height: 2px;
    }

    .rangeslider__handle {
      width: 12px !important;
      height: 12px !important;
    }
  }
}

.volumeIcon {
  margin-right: 10px;
}
