.VideoPlayer {
  width: 100%;
  height: 100%;
  position: relative;
}

.VideoPlayer__video {
  width: 100%;
  height: 100%;
}

.VideoPlayer__subs {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;

  @media (max-height: 600px) {
    bottom: 0 !important;
  }
}
