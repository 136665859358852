.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 30px;
  margin-top: 30px;
}

.fileLabel {
  margin-top: 20px;
}

.fileInput {
  margin-top: 5px;
  //  margin-left: 90px;
}

.uploadBtn {
  margin-top: 20px;
  -webkit-appearance: none;
  outline: none;
  font-size: 18px;
  padding: 12px 24px;
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: #808080 0px 1px 3px;
  border: none;

  border-radius: 6px;
  background-color: #675292;
  color: white;
  display: inline-block;
  width: 100px;
}
