.container {
  margin-bottom: 20px;
}

.simplifiedText {
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 1.5em;
}
.pinyinText {
  color: #adadad;
  padding-bottom: 5px;
}
.translatedText {
  padding-bottom: 5px;
}
