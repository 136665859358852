.container {
  box-sizing: border-box;
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  max-width: 101%;
  width: 600px;
  background-color: #333333;
  transition: transform 200ms ease-in-out;
  transform: translateX(100%);
  text-align: left;
  color: white;
  padding: 20px;
  opacity: 0.95;

  &.container__active {
    transform: translateX(0);
  }
}

.exitButton {
  position: fixed;
  top: 20px;
  right: 20px;
  align-self: center;
  padding-top: 8px;
  cursor: pointer;
}

.subtitles {
  overflow-y: scroll;
  height: 100%;
}
