@keyframes NAME-YOUR-ANIMATION {
  0% {
    opacity: 0.95;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.95;
  }
}

.container {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #1c2031;
  flex-direction: column;
  font-family: Oswald, serif;
  font-style: normal;
  font-weight: normal;

  background: radial-gradient(circle, #373944 0%, #0b0b0b 100%);
  animation: NAME-YOUR-ANIMATION 5s infinite;
}

.title {
  font-size: 60px;
  line-height: 86px;
  color: #eeeeee;
}

.subImage {
  margin-top: 60px;
  margin-bottom: 40px;
}

.subtitle {
  color: #1c2031;
  font-size: 24px;
  text-align: center;
  background-color: #eeeeee;
  padding: 2px 20px;
  border-radius: 3px;
  letter-spacing: 0.045em;
  height: 42px;
}
