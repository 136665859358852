$track-height-default: 3px;
$progress-color: #2583df;
$track-color: rgba(#ffffff, 0.2);
$buffered-color: rgba(#fff, 0.3);
$seek-hover: rgba(#fff, 0.2);

.container {
  position: relative;
}

.track {
  padding: 10px 0;
  cursor: pointer;
  outline: none;
  transition: transform 0.4s;
  transform-origin: 0 50%;
  .container:hover & {
    transform: scaleY(2);
  }
}

.trackLine {
  width: 100%;
  height: $track-height-default;
  background-color: $track-color;
  position: absolute;
  left: 0;
  top: 10 - ($track-height-default / 2);
  overflow: hidden;
  transition: transform 0.4s;
  outline: none;
  transform-origin: 0 50%;

  &.trackLine__played {
    background-color: $progress-color;
    z-index: 3;
    transform: scaleX(0);
  }

  &.trackLine__hover {
    background-color: $seek-hover;
    width: 100%;
    z-index: 4;
    transform: scaleX(0.5);
    opacity: 0;
    transition: opacity 0.4s;

    .track:hover & {
      opacity: 1;
    }
  }

  .container__isPlaying & {
    transition: transform 50ms;
  }
}

.thumb {
  pointer-events: none;
  position: absolute;
  width: 12px;
  height: 13px;
  left: -6px;
  top: 4px;
  z-index: 4;
  transition: transform 0.4s;
  transform: translateX(0);

  .container__isPlaying & {
    transition: transform 50ms;
  }
}

.handle {
  transition: transform 0.4s;
  border-radius: 100%;
  width: 100%;
  height: 100%;
  background-color: $progress-color;
  opacity: 0;
  transform: scale(0.4);

  .container:hover & {
    opacity: 1;
    transform: scale(1);
  }
}

.hoverPreview {
  transition: opacity 50ms;
  opacity: 0;
  .container:hover & {
    opacity: 1;
  }
}
