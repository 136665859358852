.container {
  width: 500px;
  background: #333333;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: white;
  font-size: 20px;
}

.header {
  border-bottom: 1px solid white;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
}
.exitButton {
  align-self: center;
  padding-top: 8px;
  cursor: pointer;
}

.headerText {
  font-size: 1.3em;
  font-weight: bold;
}

.simplifiedText {
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 1.5em;
}
.pinyinText {
  color: #adadad;
  padding-bottom: 5px;
}
.translatedText {
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  align-items: flex-end;
  width: 100%;
  justify-content: flex-end;
}

.button {
  background: #475472;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  color: white;
  padding: 5px 10px;
  outline: none;
  border: none;
  font-size: 16px;

  margin-left: 10px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    opacity: 0.9;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25);
  }
  &:focus {
    opacity: 0.8;
  }

  &.button__saved {
    background: #1bbc9b;
    box-shadow: none;
  }
}
