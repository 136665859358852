.HoverWord {
}

.HoverWord {
  display: inline-block;
  color: white;

  cursor: pointer;
  position: relative;
  border-radius: 1px;
  vertical-align: text-bottom;

  &:hover {
    background-color: #2583df;
  }

  &--notChinese {
    pointer-events: none;
  }
}

.HoverWord__hanzi {
  font-size: 36px;
}

.HoverWord__pinyin {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  height: 22px;

  padding: 0 2px;
}

.HoverWord__Tooltip {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: absolute;
  background-color: white;
  color: #333;
  bottom: 80px;
  left: 50%;
  padding: 8px;
  z-index: 2;
  border-radius: 2px;
  transform: translateX(-50%);
  min-width: 200px;
  visibility: hidden;

  .HoverWord--isHovering & {
    transition: visibility 100ms;
  }

  .HoverWord:hover &,
  .HoverWord__Tooltip:hover {
    visibility: visible;
  }

  &:before {
    position: absolute;
    content: ' ';
    bottom: -10px;
    left: calc(50% - 10px);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
  }
}

.HoverWord__TooltipHanzi {
  width: 100%;
  white-space: nowrap;
  font-size: 36px;
  font-weight: bold;
}

.HoverWord__TooltipPinyin {
  width: 100%;
  font-size: 14px;
  color: #9c9c9c;
}

.HoverWord__TooltipDefs {
  max-height: 240px;
  overflow: scroll;

  @media (max-height: 600px) {
    max-height: 100px;
  }
}

.HoverWord__TooltipDef {
  width: 100%;
  font-size: 18px;
}

.HoverWord__buttons {
  align-self: flex-end;
}

.HoverWord__addBtn {
  background: #2583df;
  color: white;
  padding: 5px 10px;
  outline: none;
  border: none;
  font-size: 12px;
  opacity: 0.5;

  margin-top: 5px;
  border-radius: 2px;
  cursor: pointer;
  text-decoration: none !important;

  &:hover {
    opacity: 0.9;
  }
}
